
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/leadercheck.ts";
import { parseTime } from "@/utils";
@Options({})
export default class extends Vue {
  private allQuestions = [
    { text: "全部问题", value: 1 },
    { text: "我创建的", value: 2 },
    { text: "我处理的", value: 3 },
  ];
  private allStatus = [
    { text: "全部状态", value: 0 },
    { text: "未处理", value: 1 },
    { text: "已处理", value: 2 },
    { text: "已解决", value: 3 },
    { text: "超期未解决", value: 4 },
  ];
  private selectType = 1;
  private selectStatus = 0;
  private show = false;
  refreshing = false;
  loading = false;
  finished = false;
  calendarShow = false;
  date: Date | string = "";

  safeList: any[] = [];
  page = {
    pageNum: 1,
    pageSize: 10,
  };

  fGoDetail(item: any) {
    this.$router.push({
      path: "/app/leaderCheckInfo",
      query: { id: item.id, status: item.status, checkType: this.selectType },
    });
  }
  fGoAdd() {
    this.$router.push("/app/leadercheckadd");
  }
  currentDate() {
    return parseTime(this.date, "{m}月{d}日");
  }
  fConfirmDate(d: Date) {
    this.date = d;
    this.fChangeSearch();
    this.show = false;
  }
  fChangeSearch() {
    this.refreshing = true;
    this.onRefresh();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.safeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const reqjson = {
        projectId: this.$store.state.project.projectinfo.projectId,
        status: this.selectStatus ? this.selectStatus : null,
        createDate: this.date ? parseTime(this.date, "{y}-{m}-{d}") : null,
        ...this.page,
      };
      let runfun = null;

      if (this.selectType === 1) {
        runfun = api.projectLeaderCheckPageall(reqjson);
      } else if (this.selectType === 2) {
        runfun = api.projectLeaderCheckPageCreate(reqjson);
      } else {
        runfun = api.projectLeaderCheckPageDeal(reqjson);
      }

      runfun.then((res: any) => {
        this.safeList = [...this.safeList, ...res.data.records];
        this.page.pageNum++;
        this.loading = false;
        if (this.safeList.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }

  protected remove(item: any) {
    this.$dialog
      .confirm({
        title: "提示",
        message: "此操作将删除改条目，是否继续？",
      })
      .then(() => {
        api.projectLeaderCheckRemove(item.id).then((res: any) => {
          if (res.code === 0) {
            this.$toast.success(res.msg);
            this.refreshing = true;
            this.onRefresh();
          } else {
            this.$toast.fail(res.msg);
          }
        });
      })
      .catch((err) => {
        // console.log("err");
      });
  }

  fSetTime() {
    this.date = "";
    this.selectType = 1;
    this.selectStatus = 0;
    this.show = false;
    this.fChangeSearch();
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
